import React from 'react';
import "./VBelt.css"

const VBelt = () => {
  return (
    <div>
       <div className='my_chart2'>
		<h3>Classic Industrial V-Belts</h3>

	  <div style={{overflowY:"scroll",height:"1500px"}}>
		 <div  className='image_mg_container'>
         
    <div className="v-belt-container">
      <h1>Classic Industrial V-Belts</h1>
      <p>
        Umang Marketing is the authorized distributor for top-quality Classic Industrial V-Belts,
        designed especially for reverse-bend drive by positioning tensile members closer to the
        bottom compared to that of conventional multiple V-belts A, B, & C.
      </p>
      <p>
        Particularly suitable for Agricultural & Industrial Machinery, where V-belts are often
        driven with tension idler pulley on their back. Heat, Oil, and Anti-Static resistant.
      </p>

      <h2 >1. Escon V-Belts</h2>
      <ul>
        <li>
          <strong>Cover:</strong> The cut cover fabric is impregnated with special NEOPRENE rubber
          compound to provide strong abrasion resistance on the pulley groove and to protect the
          inner part of the belts.
        </li>
        <li>
          <strong>Tension Member:</strong> Specially treated tough polyester cords impart high
          strength, low elongation, and excellent resistance to bending fatigue.
        </li>
        <li>
          <strong>Top Compression Section:</strong> High heat dissipating rubber compound withstands
          increased flexing and sudden shock loads.
        </li>
        <li>
          <strong>Insulation Section:</strong> Holds the tension members in place and acts as a
          binding force between cords, top, and bottom compression sections.
        </li>
        <li>
          <strong>Bottom Compression Section:</strong> Specially compounded to resist compression
          fatigue and heat for a longer service life.
        </li>
      </ul>

      <h3>ESCON V-BELT SPECIFICATIONS</h3>
      <table class="v-belt-table" width="100%" border="0px" cellpadding="1px" cellspacing="1px" align="center">
    <tbody><tr bgcolor="#004a62">
	<th width="100%" colspan="11" align="center"><strong><font size="2" color="#ffffff">ESCON V-BELT SPECIFICATIONS</font></strong></th>
    </tr>

    <tr bgcolor="#ECF2F8">
	<th colpan="1" align="center"><strong><font size="2" color="#000000">Belt Section</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">Nominal Top Width</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">Nominal Thickness</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">Nominal Angle</font></strong></th>
	<th width="18%" colspan="2" align="center"><strong><font size="2" color="#000000">Belt Length Range Inside Length</font></strong></th>
	<th rowspan="1" align="center"><strong><font size="2" color="#000000">Minimum Recommended Pulley Pitch Dia</font></strong></th>
	<th width="18%" colspan="2" align="center"><strong><font size="2" color="#000000">IS : 2494 Specifications</font></strong></th>
	<th width="17%" colspan="2" align="center"><strong><font size="2" color="#000000">ESCON Results</font></strong></th>
    </tr>


    <tr bgcolor="#F9FCFE">
	<th colspan="1" align="center"><strong><font size="2" color="#000000"> </font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">(mm)</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">(mm)</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">(°)</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">(Inch)</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">(mm)</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">(mm)</font></strong></th>
	<th align="center"><strong><font size="2" color="#000000">Minimum Tensile Strength (Kgs)</font></strong></th>
	<th align="center"><strong><font size="2" color="#000000">Minimum Elongation at Break (%)</font></strong></th>
	<th align="center"><strong><font size="2" color="#000000">Tensile Strength (Kgs)</font></strong></th>
	<th align="center"><strong><font size="2" color="#000000">Elongation at Break (%)</font></strong></th>
    </tr>

    <tr bgcolor="#ECF2F8">
	<th colspan="1" align="center"><strong><font size="2" color="#000000">A</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">13.0</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">8.0</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">40</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">16-180</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">406-4572</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">80</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">300</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">15.0</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">370</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">11.0</font></strong></th>
    </tr>

    <tr bgcolor="#F9FCFE">
	<th colspan="1" align="center"><strong><font size="2" color="#000000">B</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">17.0</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">11.0</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">40</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">20-400</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">508-10160</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">125</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">425</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">15.0</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">550</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">11.0</font></strong></th>
    </tr>

    <tr bgcolor="#ECF2F8">
	<th colspan="1" align="center"><strong><font size="2" color="#000000">C</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">22.0</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">14.0</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">40</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">36-600</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">914-15240</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">200</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">800</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">15.0</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">1050</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">11.0</font></strong></th>
    </tr>

    <tr bgcolor="#F9FCFE">
	<th colspan="1" align="center"><strong><font size="2" color="#000000">D</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">32.0</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">19.0</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">40</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">88-600</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">2225-15240</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">355</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">@460 Kg Load</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">5.0</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">@460 Kg Load</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">3.0</font></strong></th>
    </tr>

    <tr bgcolor="#ECF2F8">
	<th colspan="1" align="center"><strong><font size="2" color="#000000">E</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">38.0</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">23.0</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">40</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">158-600</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">4013-15240</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">500</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">@612 Kg Load</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">5.0</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">@612 Kg Load</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">3.0</font></strong></th>
    </tr>

</tbody></table>

      <h2>2. Escon Wedge Belts</h2>
      <p>
        Escon Wedge Belts contain high modulus polyester cords, which together with specially
        formulated bottom compression rubber compound and insulation rubber, constitute a solid union
        under severe flexing conditions. The cords retain their designed position under tension
        without any movement, ensuring efficient power transmission and space-saving benefits.
      </p>

      <h3>ESCON WEDGE BELT SPECIFICATIONS</h3>
      <table class="v-belt-table" width="100%" border="0px" cellpadding="1px" cellspacing="1px" align="center">
    <tbody><tr bgcolor="#004a62">
	<th width="100%" colspan="6" align="center"><strong><font size="2" color="#ffffff">ESCON WEDGE BELT SPECIFICATIONS</font></strong></th>
    </tr>

    <tr bgcolor="#ECF2F8">
	<th colpan="1" align="center"><strong><font size="2" color="#000000">Belt Section</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">Nominal Top Width (mm)</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">Nominal Thickness (mm)</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">Nominal Angle (°)</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">Belt Length Range Pitch Length (mm)</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">Minimum Recommended Pulley Pitch Dia (mm)</font></strong></th>
    </tr>


    <tr bgcolor="#F9FCFE">
	<th colspan="1" align="center"><strong><font size="2" color="#000000">SPZ</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">10.0</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">8.0</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">40</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">630-3550</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">63</font></strong></th>
    </tr>

    <tr bgcolor="#ECF2F8">
	<th colspan="1" align="center"><strong><font size="2" color="#000000">SPA</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">13.0</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">10.5</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">40</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">800-4500</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">90</font></strong></th>
    </tr>

    <tr bgcolor="#F9FCFE">
	<th colspan="1" align="center"><strong><font size="2" color="#000000">SPB</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">17.0</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">13.5</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">40</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">1259-10000</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">160</font></strong></th>
    </tr>

    <tr bgcolor="#ECF2F8">
	<th colspan="1" align="center"><strong><font size="2" color="#000000">SPC</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">22.0</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">18.0</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">40</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">2000-12500</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">224</font></strong></th>
    </tr>

</tbody></table>
      <h2>3. Escon Raw Edge Cogged Belts</h2>
      <p>
        The construction of Escon Raw Edge Cogged Belts involves the following components: top fabric,
        tension members, compression rubber, and bottom fabric, all working together to provide
        superior flexibility, wear resistance, and long service life.
      </p>

      <h3>ESCON RAW EDGE COGGED BELT SPECIFICATIONS</h3>
      <table class="v-belt-table" width="100%" border="0px" cellpadding="1px" cellspacing="1px" align="center">
    <tbody><tr bgcolor="#004a62">
	<th width="100%" colspan="4" align="center"><strong><font size="2" color="#ffffff">ESCON RAW EDGE COGGED BELT SPECIFICATIONS (All Dimensions in MM)</font></strong></th>
    </tr>

    <tr bgcolor="#ECF2F8">
	<th colpan="1" align="center"><strong><font size="2" color="#000000">Cross Section</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">Nominal Top Width (mm)</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">Nominal Thickness (mm)</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">Angle (°)</font></strong></th>
    </tr>


    <tr bgcolor="#F9FCFE">
	<th colspan="1" align="center"><strong><font size="2" color="#000000">AX</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">13.0</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">8.0</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">36</font></strong></th>
    </tr>

    <tr bgcolor="#ECF2F8">
	<th colspan="1" align="center"><strong><font size="2" color="#000000">BX</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">17.0</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">11.0</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">36</font></strong></th>
    </tr>

    <tr bgcolor="#F9FCFE">
	<th colspan="1" align="center"><strong><font size="2" color="#000000">CX</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">22.0</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">14.0</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">36</font></strong></th>
    </tr>

    <tr bgcolor="#ECF2F8">
	<th colspan="1" align="center"><strong><font size="2" color="#000000">10 MM</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">10.0</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">8.0</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">34/36</font></strong></th>
    </tr>

</tbody></table>

      <h2>4. Escon Conveyor Belting</h2>
      <p>
        Escon Conveyor Belts are available in standard widths ranging from 150 mm to 2000 mm, designed
        for various applications with cover grades to suit different conditions such as heat,
        abrasion, oil, and chemical resistance.
      </p>

      <h3>ESCON RAW CONVEYOR BELTING SPECIFICATIONS</h3>
      <table class="v-belt-table" width="100%" border="0px" cellpadding="1px" cellspacing="1px" align="center">
    <tbody><tr bgcolor="#004a62">
	<th width="100%" colspan="2" align="center"><strong><font size="2" color="#ffffff">ESCON RAW CONVEYOR BELTING SPECIFICATIONS</font></strong></th>
    </tr>

    <tr bgcolor="#ECF2F8">
	<th colpan="1" align="center"><strong><font size="2" color="#000000">BELT TYPE</font></strong></th>
	<th colspan="1" align="center"><strong><font size="2" color="#000000">GENERAL APPLICATION</font></strong></th>
    </tr>


    <tr bgcolor="#F9FCFE">
	<th colspan="1" align="center"><strong><font size="2" color="#000000">M-24</font></strong></th>
	<th colspan="1" align="left"><strong><font size="2" color="#000000">Suitable for rugged services as crushed metallic ores, limestone, granite etc.</font></strong></th>
    </tr>

    <tr bgcolor="#ECF2F8">
	<th colspan="1" align="center"><strong><font size="2" color="#000000">N-17</font></strong></th>
	<th colspan="1" align="left"><strong><font size="2" color="#000000">Suitable for moderately abrasives like Asbestos, Ash Bauxite Cement, Lime etc.</font></strong></th>
    </tr>

    <tr bgcolor="#F9FCFE">
	<th colspan="1" align="center"><strong><font size="2" color="#000000">HR T1</font></strong></th>
	<th colspan="1" align="left"><strong><font size="2" color="#000000">Suitable for transporting hot material (upto 125°C for coarse material and 100° for fines)</font></strong></th>
    </tr>

    <tr bgcolor="#ECF2F8">
	<th colspan="1" align="center"><strong><font size="2" color="#000000">HR T2</font></strong></th>
	<th colspan="1" align="left"><strong><font size="2" color="#000000">Suitable for transporting hot material (upto 150°C for coarse material and 125° for fines)</font></strong></th>
    </tr>

    <tr bgcolor="#F9FCFE">
	<th colspan="1" align="center"><strong><font size="2" color="#000000">SHR T3</font></strong></th>
	<th colspan="1" align="left"><strong><font size="2" color="#000000">Suitable for transporting hot material above 150°C and up to 200°</font></strong></th>
    </tr>

    <tr bgcolor="#ECF2F8">
	<th colspan="1" align="center"><strong><font size="2" color="#000000">Oil Resistant (OR)</font></strong></th>
	<th colspan="1" align="left"><strong><font size="2" color="#000000">Compounded to give very high resistance to minerals &amp; vegitable oil etc.</font></strong></th>
    </tr>

    <tr bgcolor="#F9FCFE">
	<th colspan="1" align="center"><strong><font size="2" color="#000000">Food Conveyor (Hygienic)</font></strong></th>
	<th colspan="1" align="left"><strong><font size="2" color="#000000">Suitable for handling confectionary, food, pharmaceuticals etc.</font></strong></th>
    </tr>

    <tr bgcolor="#ECF2F8">
	<th colspan="1" align="center"><strong><font size="2" color="#000000">Fire Resistant (FR)</font></strong></th>
	<th colspan="1" align="left"><strong><font size="2" color="#000000">Recommended for use in coal &amp; such types of mines where ambient temparature may not be high but there is distinct hazard of belts being enveloped in fire.</font></strong></th>
    </tr>

</tbody></table>

      <h2>5. Escon Flexible Couplings</h2>
      <p>Escon provides a range of flexible couplings including Tyre Couplings, Jaw Couplings, Star Couplings, and Bush Type Couplings.</p>
      <div className='img-div'>
        <h5>Jaw Couplings</h5>
      <img src="./img/jaw-5.jpg" title="Jaw Couplings" width="200" height="200"/>
      <h5>Tyre Couplings</h5>
     <img src="./img/T-5.jpeg" title="Tyre Couplings" width="200" height="200"/>
     <h5>Star Type Couplings</h5>
      <img src="./img/s-5.jpg" title="star Couplings" width="200" height="200"/>
      <h5>Bush Type Couplings</h5>
     <img src="./img/b-5.jpg" title="Bush Couplings" width="200" height="200"/>
      </div>
    </div>
  

   
		 </div>
	  </div>
    </div>
    </div>
  )
}

export default VBelt
