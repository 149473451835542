import React,{useEffect} from 'react';
import styled from '@emotion/styled';
import { FaArrowCircleRight } from "react-icons/fa"
import AOS from "aos";
import "aos/dist/aos.css";
import PhoneIcon from '@mui/icons-material/Phone';
import { CiSaveDown2 } from "react-icons/ci";
import './Tenryu.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import OurPartners from '../Components/OurPartners';
import { MdOutlineDoubleArrow } from "react-icons/md";

const Container = styled.div`
  max-width: 1200px;
  margin: auto;
  padding: 24px;
  animation: fadeIn 1s ease-in;

  @keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
  }
`;
const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 4, // Adjust based on how many slides you want to show
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 1000,
  responsive: [
    {
      breakpoint: 768, // Mobile view adjustments
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      }
    },
    {
      breakpoint: 480, // Smaller mobile view adjustments
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      }
    }
  ]
};
const Card = styled.div`
  background-color: #f0f8ff;
  margin-bottom: 24px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
  border-radius: 8px;
  overflow: hidden;

  &:hover {
    transform: translateY(-5px);
  }
`;

const CardContent = styled.div`
  padding: 24px;
`;

const Title = styled.h2`
  color: #004a62;
  margin-bottom: 16px;
`;

const Image = styled.img`
  max-width: 100%;
  height: auto;
`;

const Text = styled.p`
  color: #333;
  margin-bottom: 16px;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 16px;
`;

const TableHead = styled.thead`
  background-color: #f68a0a;
`;

const TableCell = styled.td`
  padding: 12px;
  border: 1px solid #ddd;
`;

const TableHeaderCell = styled.th`
  padding: 12px;
  border: 1px solid #ddd;
  color: white;
  background-color: #f68a0a;
`;


const Tenryu = () => {

  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top of the page
  }, []);
  useEffect(() => {
    AOS.init();

  }, []);

  return (
    <>
    <div class="container-fluid bg-breadcrumb_tenryu">
    <div class="container text-center py-5" style={{maxWidth: "900px", }}>
                {/* <img src="./img/wikuslogo.jpeg" data-wow-delay="0.1s" style={{borderRadius:"20px",height:"100px",width:"150px"}}/> */}
                <ol class="breadcrumb d-flex justify-content-center mb-0 wow fadeInDown" data-wow-delay="0.3s" style={{marginTop:'170px'}}>
                    <li class=""><a href="/"><strong>Home</strong></a><MdOutlineDoubleArrow/></li>
                    {/* <li class="breadcrumb-item"><a href="#">Pages</a></li> */}
                    <li class="breadcrumb-item active text-primary"><strong>Tenryu</strong></li>
                </ol>    
            </div>
        </div>

<div className="hardcastel-container">
      <div className="hardcastel-right-column">
      <div style={{display:"flex",justifyContent:"space-between",flexWrap:"wrap"}}>
      <h1 style={{textAlign:"left",color:"#0f253b"}}><strong>Tenryu</strong></h1>
      <a href="./img/tenryu.pdf" download="tenryucatalog">
        <button className='btn btn-outline-primary btn-page-pulse'><CiSaveDown2/>Catalog Download</button>    
      </a>
  
    </div>

      
        <p data-aos="fade-up" className='chart-container'>A company focused on sawing excellence, TENRYU boasts the best circular saw blades, from HSS to carbide tipped circular saw blades. 
<span style={{color:"#f68a0a"}}>TENRYU mission is to deliver products that match newly developed materials and advanced cutting conditions.</span>
We begin a cutting test by using a testing machine from a pre-production stage of a work material. We work closely with our customers to single out the most suitable tooth tip material, or to develop the tooth tip material that best suits the properties of the work material. Through these trial cutting sessions, we are able to suggest the most suitable cutting parameters, and to deliver the best saw for each individual user.</p>
      <div className="img_container_tenryu">
            <div className='chart-container' >
              <img src="./img/Tenryu1.png" alt="" />  
              <img src="./img/Tenryu2.png" alt="" />
           
</div>
         
            <div className='chart-container' >
                <img src="./img/Tenryu3.png" alt="" /> 
                <img src="./img/Tenryu4.png" alt="" /> 
            </div>
          </div>
           <br/>

           {/* <div class="container-fluid banner py-5 wow zoomIn" data-wow-delay="0.2s">
            <div class="banner-design-1"></div>
            <div class="banner-design-2"></div>
            <div class="container py-5">
                <div class="row g-5">
                    <div class="col-lg-7">
                        <div class="">
                            <h4 class="text-white">Contact Us</h4>
                            <h1 class="display-4 text-white mb-0">We Provide Professional  Sawing Solutions for our customer</h1>
                            <a class="btn btn-primary py-3 px-4 px-md-5 ms-2" href="/contact">EXPLORE MORE</a>
                        </div>
                    </div>
                    <div class="col-lg-3">
                        <div class="d-flex align-items-center justify-content-lg-end h-100">
                     <a class="btn btn-outline-primary py-3 px-4 px-md-5 ms-2" href="/Contact"><PhoneIcon />CALL NOW</a>
                        </div>
                    </div>
                </div>
            </div>
         </div> */}


         {/* <div className="cutting-info-container">
      

      <div className="cutting-container">
      <div className="left-section">
        <div className="needs">
          <h2>NEEDS</h2>
          <ul>
            <li>Cutting Quality</li>
            <li>Cutting Costs &gt; Post-Processing Costs</li>
            <li>Productivity</li>
            <li>Environmental Issues</li>
            <li>Automation | Labor Saving</li>
          </ul>
        </div>

        <div className="solution">
          <h2>SOLUTION</h2>
          <h3>Carbide Tipped Saw Blades for Metal</h3>
          <ul>
            <li>Tooth Tip Material</li>
            <li>Tooth Type</li>
            <li>Durability</li>
            <li>Ability to Hold a Straight Cutting</li>
            <li>Reduction of Vibration and Noise</li>
            <li>Cutting Surface | Surface Roughness</li>
            <li>Cutting Speed</li>
            <li>Reduction of Dust</li>
          </ul>

          <h3>Cutting Machine</h3>
          <ul>
            <li>Automation | Labor Saving</li>
            <li>Production Speed</li>
            <li>Subsequent Processing Costs</li>
            <li>Suggestions for the Most Suitable Cutting Requirements</li>
          </ul>
        </div>
      </div>

      <div className="right-section">
        <div className="environment-info">
          <h4>The environment surrounding cutting work</h4>
          <p>
            In the area of cutting work, requirements have become increasingly
            strict, such as diversification of materials and greater sophistication
            in the quality of cutting.
          </p>
        </div>

        <div className="pas-brand">
          <h4>The PAS brand, in Pursuit of the Best</h4>
          <p>
            Demands for cutting tools have become increasingly stringent in recent years, such as in terms of processing quality, durability, and productivity. In addition, the properties of materials and forms of work materials have diversified.
          </p>
          <p>
            We propose the most suitable cutting tools for specific conditions. We believe that the solution with the best value is that attained when any distance between the user and the PAS brand has reached close to the point of zero.
          </p>
        </div>

        <div className="resharpening-info">
          <h4>Our commitment to cutting extends into resharpening</h4>
          <p>
            Another of our important themes is to maintain the performance of your saw blade at its very best. We inspect the blade in detail after use and analyze the cutting condition during use. Based on these results, we propose the most economical form of processing.
          </p>
          <img src="./img/needs.png" />
        </div>
      </div>
    </div>
    </div>


    <Container data-aos="fade-up">
    <Card style={{ display: 'flex', alignItems: 'center' }}>
    <CardContent className='chart-container resposnive_tenryu_tct'>
      <div style={{ flex: '1' }}>
        <Title style={{textAlign:"left",borderRadius:"7px"}} class="btn btn-outline-primary">Tenryu-Japan TCT & HSS Circular Cutters</Title>
        <Text>
          <strong>Umang Marketing</strong> is the authorized distributor for a wide range of international repute <strong>Tenryu's Premium Quality Japanese made TCT & HSS Circular Cutters, TCT Circular Saw for Heavy Duty & Light Duty Cutting, TCT Circular Saw for Tube & Pipe, HSS Circular Saw for Ferrous & Non-Ferrous Metals.</strong>
        </Text>
      </div>
      <Image src="./img/productimage.png" alt="Tenryu Circular Cutters" style={{ marginLeft: '20px', maxWidth: '200px' }} />
    </CardContent>
  </Card>

      <Card >
        <CardContent  className='chart-container resposnive_tenryu_tct'>
          <div >
            <Title style={{textAlign:"left",borderRadius:"7px"}} class="btn btn-outline-primary">TCT Circular Saw For Heavy & Light-Duty Cutting - Throw Away Type</Title>
            <Image src="./img/productimage1.png" alt="TCT Circular Saw" />
          </div>
          
          <Text style={{margin:"15px"}}>
            <strong>Technical Specifications:</strong>
            <br />
            <strong><FaArrowCircleRight style={{color:"#0f253b",marginRight:"7px"}}/>Materials:</strong> Structural Steel, Tool Steel, Alloy Steel, Stainless Steel, Aluminum Alloy, Copper Alloy
            <br />
            <strong><FaArrowCircleRight style={{color:"#0f253b",marginRight:"7px"}}/>Shape of Materials:</strong> Bar Steel, Bar Stainless Steel, Solid - drown pipe, Deformed Material
            <br />
            <strong><FaArrowCircleRight style={{color:"#0f253b",marginRight:"7px"}}/>Blade Dimensions:</strong> Diameter: 200~910; Kerf: 1.0~4.5; Teeth: 40~300
            <br />
            <strong><FaArrowCircleRight style={{color:"#0f253b",marginRight:"7px"}}/>Tooth Geometry:</strong> Notched Breaker
            <br />
            <strong><FaArrowCircleRight style={{color:"#0f253b",marginRight:"7px"}}/>Tooth Material:</strong> Carbide P30, Cermet, PVD coating TiN, AlTiN, CrN
            <br />
            <strong><FaArrowCircleRight style={{color:"#0f253b",marginRight:"7px"}}/>Features:</strong> Despite its thin kerf, this model boasts longevity, perpendicularity, and a smooth cut surface.
          </Text>

          <Table>
            <TableHead>
              <tr>
                <TableHeaderCell>Image</TableHeaderCell>
                <TableHeaderCell>Materials</TableHeaderCell>
              </tr>
            </TableHead>
            <tbody>
              <tr>
                <TableCell>
                  <Image src="./img/productimage2.png" alt="Product Image" style={{borderRadius:"7px"}}/>
                </TableCell>
                <TableCell>
                  Structural Steel, Tool, Alloy Steel, Stainless Steel, Aluminum Alloy, Copper Alloy
                </TableCell>
              </tr>
            </tbody>
          </Table>
        </CardContent>
      </Card>
    </Container> */}

<div class="products_contentinnner">

			

<h1 style={{color:"#0f253B"}}>HSS Tools</h1>

<div className='chart-container'>
<div class="itiranbox" id="post-264">
<div class="itiran_photo"><a href=""><img width="148" height="148" src="./img/metal_tin1.jpeg" class="attachment-148x148 size-148x148 wp-post-image" alt="TiN" /></a></div>

<div class="shohin_yoto">
<div class="itiran_caption" style={{textAlign:"center",fontSize:"20px",fontWeight:"bold"}}>TiN</div>

					<p>Only a few microns thick, the PVD coating increases surface hardness and, along with the low friction coefficient, prevents built up edge. Also, the feed speed can be improved by about 50% compared to non-coated products, thanks to the high thermal resistance. As a result, cutting time is reduced, the saw needs to be replaced less often, and its life is also lengthened.</p>
			  <p>・PVD coated<br/>
						・Surface handness：HV 2200-2400<br/>
						・Oxidizing temperature：600℃<br/>
						・Friction coefficient：0.55<br/>
					 ・Application：Mild steel, Medium hardness steel</p>
				</div>
</div>
</div>

<div className='chart-container'>
<div class="itiranbox" id="post-270">
<div class="itiran_photo"><a href="https://tenryu-saw.com/en/ichiran/hamono/hss/hss01/270/"><img width="148" height="148" src="./img/metal_titain-2.jpeg" class="attachment-148x148 size-148x148 wp-post-image" alt="TiAlN" loading="lazy" title="TiAlN"/></a></div>

<div class="shohin_yoto">
<div class="itiran_caption" style={{textAlign:"center",fontSize:"20px",fontWeight:"bold"}}>TiAlN</div>
					<p>This blade is optimal for cutting high-tensile steel material or stainless steel. Thanks to the high thermal resistance and low friction coefficient, high-speed dry cutting is supported.</p>
					<p>・PVD coated<br/>
						・Surface handness：HV3300-3500<br/>
						・Oxidizing temperature：800℃<br/>
						・Friction coefficient：0.60<br/>
					・Application：High hardness steel, Stainless steel, titanium steel	</p>
				</div>
</div>
</div>


<div className='chart-container'>
<div class="itiranbox" id="post-272">
<div class="itiran_photo"><a href="https://tenryu-saw.com/en/ichiran/hamono/hss/hss01/272/"><img width="148" height="148" src="./img/metal_crn-3.jpeg" class="attachment-148x148 size-148x148 wp-post-image" alt="CrN" loading="lazy" title="CrN"/></a></div>

<div class="shohin_yoto">
<div class="itiran_caption" style={{textAlign:"center",fontSize:"20px",fontWeight:"bold"}}>CrN</div>
					<p>The advantages of this PVD coating are coat thickness, high surface hardness, and a low friction coefficient. This saw is optimal for cutting sticky materials where gumming happens easy, such as non-ferrous metals.</p>
					<p>・PVD coated<br/>
						・Surface handness：HV2500-2900<br/>
						・Oxidizing temperature：700℃<br/>
						・Friction coefficient：0.30<br/>
					・Application：Nonferrous metal such as Cast iron, Aluminum and Copper	</p>
				</div>
</div>
</div>

<div className='chart-container'>
<div class="itiranbox" id="post-277">
<div class="itiran_photo"><a href="https://tenryu-saw.com/en/ichiran/hamono/hss/hss01/277/"><img width="148" height="148" src="./img/metal_ov_4.jpeg" class="attachment-148x148 size-148x148 wp-post-image" alt="OV" loading="lazy" title="OV"/></a></div>

<div class="shohin_yoto">
<div class="itiran_caption" style={{textAlign:"center",fontSize:"20px",fontWeight:"bold"}}>OV</div>
					<p>The entire blade is steam treated and has a black oxide (Fe3 O4) coating wﾎth oil-bearing properties.<br/>
				    Use it for ordinary material cutting, except for non-ferrous metals.</p>
					<p>・Surface handness：HV900<br/>
					  ・Friction coefficient：0.65<br/>
					  ・Application：Common	steel	excerpt	nonferrous	metal</p>
				</div>
</div>
</div>

<div className='chart-container'>
<div class="itiranbox" id="post-279">
<div class="itiran_photo"><a href="https://tenryu-saw.com/en/ichiran/hamono/hss/hss01/279/"><img width="148" height="148" src="./img/metal_white-5.jpeg" class="attachment-148x148 size-148x148 wp-post-image" alt="White" loading="lazy" title="White"/></a></div>

<div class="shohin_yoto">
<div class="itiran_caption" style={{textAlign:"center",fontSize:"20px",fontWeight:"bold"}}>White</div>
				  <p>This blade has no surface treatment and is mainly used for cutting non-ferrous metals.</p>
                  <p>・Surface handness：HV750-850<br/>
                    ・Application：Nonferrous metal such as Aluminum and Copper</p>
                  <p class="youtopic">&nbsp;</p>
				</div>
</div>
</div>


{/* <div class="itiranbox" id="post-402">
<div class="itiran_photo"><a href="https://tenryu-saw.com/en/ichiran/hamono/hss/hss02/402/"><img width="148" height="148" src="./img/t14_i1.gif" class="attachment-148x148 size-148x148 wp-post-image" alt="Metal Band Saw Blade" loading="lazy" title="Metal Band Saw Blade"/></a></div>
<div class="itiran_caption">Metal Band Saw Blade</div>
</div> */}

<br class="clearfloat"/>
</div>
<OurPartners/>
    </div>
    </div>
    </>
  );
};

export default Tenryu;