import React, { useEffect } from 'react';
import './Popup.css';
import { motion } from 'framer-motion';
import { CgCloseO } from "react-icons/cg";
import AOS from "aos";
import "aos/dist/aos.css";
import Marquee from "react-fast-marquee";
import { TypeAnimation } from 'react-type-animation';

const Popup = ({ onClose }) => {
  useEffect(() => {
    // Disable scrolling when the popup is open
    document.body.style.overflow = 'hidden';
    
    // Automatically close the popup after 5 seconds (5000 ms)
    const timer = setTimeout(() => {
      onClose(); // Trigger the close function
    }, 15000);

    // Clean up and enable scrolling when the popup is closed
    return () => {
      document.body.style.overflow = 'auto';
      clearTimeout(timer); // Clear the timer if the component unmounts
    };
  }, [onClose]);

  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <div className="popup-overlay">
      <motion.div
        className="popup"
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <div className='popupcontainer'>
          <div>
            <img src="img/aph.png" className='popupimage' data-aos="zoom-in" />
            <img src="img/aph2.jpg" className='popupimage' data-aos="zoom-in" />
            <img src="img/aph4.jpg" className='popupimage' data-aos="zoom-in" style={{ height: "190px" }} />
          </div>
          <div className='popupcontent'>
            <div className='new_container'>
              <h3 className='popheading'>ISO Certified Company 9001: 2015</h3>
              <CgCloseO style={{ color: "red", fontSize: "40px" }} onClick={onClose} className='close-icon'/>
            </div>

            <TypeAnimation className='popheading'
              sequence={[
                'Umang',
                1000,
                'Umang Marketing',
                1000,
                'Umang Marketing &',
                1000,
                'Umang Engineering Service',
                1000
              ]}
              wrapper="span"
              speed={50}
              style={{ fontSize: '1.8em', display: 'inline-block', color: "black" }}
              repeat={Infinity}
            />
            <p className='popupparagraph' style={{ fontWeight: "bold" }}>In Business Associated with Various Segments of Engineering Industries Since 1993...</p>
            <img src="img/aph3.jpg" className='popupimage' style={{ margin: "10px" }} />
            <img src="img/aph5.jpg" className='popupimage' />
          </div>
        </div>
        <Marquee style={{ fontWeight: "bold", color: "black" }}>
          With each machine we are giving complimentary 3nos WIKUS M42 bandsaw machine/2 nos TENRYU circular saw blade, Extended <span style={{ color: "#f68a0a",marginLeft:"3px" }}> free AMC </span>.
        </Marquee>
      </motion.div>
    </div>
  );
};

export default Popup;