import React,{ useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { MdOutlinePhoneInTalk } from "react-icons/md";
import { FaBars, FaTimes } from 'react-icons/fa';
import HomeWorkOutlinedIcon from '@mui/icons-material/HomeWorkOutlined';
import Groups2OutlinedIcon from '@mui/icons-material/Groups2Outlined';
import InventoryOutlinedIcon from '@mui/icons-material/InventoryOutlined';
import PrecisionManufacturingOutlinedIcon from '@mui/icons-material/PrecisionManufacturingOutlined';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import ViewQuiltOutlinedIcon from '@mui/icons-material/ViewQuiltOutlined';
import PermContactCalendarOutlinedIcon from '@mui/icons-material/PermContactCalendarOutlined';
import { TbCertificate } from "react-icons/tb";
import './Navbar.css'

const Navbar = () => {
    const [isSticky, setIsSticky] = useState(false);
const [isMenuOpen, setIsMenuOpen] = useState(false); // State for menu open/close

    
    useEffect(() => {
             const handleScroll = () => {
               if (window.scrollY > 100) {
                 setIsSticky(true);
               } else {
                 setIsSticky(false);
               }
             };
        
             window.addEventListener('scroll', handleScroll);
        
             return () => {
               window.removeEventListener('scroll', handleScroll);
             };
          }, []);


 const toggleMenu = () => {
 setIsMenuOpen(!isMenuOpen);
};
const closeMenu = () => {
  setIsMenuOpen(false);
};

  return (
    <div >

        <div class="container-fluid header-top">
        <div class="container d-flex align-items-center">
        <div class="d-flex align-items-center h-100">
        <Link to="/" class="navbar-brand" style={{height: "125px"}}>
        <h1 class="text-primary mb-0">
           <a href='/'><img src="img/422.png" alt="" style={{height:"113px",marginTop:"-30px"}}/>
           </a> 
           
        </h1>
     

        </Link>
        </div>
        <div class="w-100 h-100">
        <div class="topbar px-0 py-2 d-none d-lg-block" style={{height: "45px"}}>
        <div class="row gx-0 align-items-center">
        <div class="col-lg-8 text-center text-lg-center mb-lg-0">
        <div class="d-flex flex-wrap">
        <div class="border-end border-primary pe-3" style={{marginLeft:"-90px"}}>
        <a href="https://maps.app.goo.gl/GmzotUiaGzyg9Sej7" class="text-muted small"><i class="fas fa-map-marker-alt text-primary me-2"></i><span style={{fontSize:"12px"}}>SM-118, ITI Industrial Estate (Dyavasandra Industrial Estate) , Whitefield Main Rd, Mahadevapura, Bengaluru-560048</span></a>
        </div>
        
        </div>
        </div>
        <div class="col-lg-4 text-center text-lg-end">
        <div class="d-flex justify-content-end">
        <div class="d-flex border-end border-primary pe-3">
        <a class="btn p-0 text-primary me-3" href="mailto:umangmrkt@gmail.com" style={{marginLeft:"-90px"}}><i class="fas fa-envelope text-primary me-2" style={{marginRight:"5px"}}></i><span style={{color:"white",marginRight:"9px",fontSize:"12px"}}>umangmrkt@gmail.com/sales@umangmarketingblr.com</span></a>
        </div>
      
        </div>
        </div>
        </div>
        </div>
        <div class={`sticky-wrapper nav-bar px-0 py-lg-0 responsive_sticky ${isSticky ? ' sticky' : ''}`} style={{height: "80px"}}>
        <nav class="navbar navbar-expand-lg navbar-light d-flex justify-content-lg-end">
        <Link to="/" class="navbar-brand-2">
        <img src="img/422.png" alt="" style={{height:"77px",marginTop:"15px"}}/>

        </Link>
        <div className="mobile-phone-number d-lg-none" style={{ marginLeft: 'auto' }}>
                                    <a href="tel:+918041145681" className="text-primary" style={{ display: 'flex', alignItems: 'center',justifyContent:"start", textDecoration: 'none' }}>
                                        <MdOutlinePhoneInTalk style={{ fontSize: '10px', marginRight: '5px' }} />
                                        <span style={{ fontSize: '11px', color: 'black' }}>+91 8041145681<br/>9606645009</span>
                                    </a>
                                </div>
        <button className="navbar-toggler" type="button" onClick={toggleMenu} aria-expanded={isMenuOpen}>
                 
                 <span>{isMenuOpen ? <FaTimes /> : <FaBars />}</span>
               </button>
        <div className={`collapse navbar-collapse ${isMenuOpen ? 'show' : ''}`} id="navbarCollapse" style={{color:"black"}}>
        <div class="navbar-nav mx-0 mx-lg-auto" >
        <Link to="/" class="nav-item nav-link active" onClick={closeMenu}><span className='responive_home_icon' ><HomeWorkOutlinedIcon /></span>Home</Link>
        <Link to="/about" class="nav-item nav-link" onClick={closeMenu}><span className='responive_home_icon' ><Groups2OutlinedIcon /></span>About</Link>
        <div class="nav-item dropdown">
        <Link to="/products" class="nav-link" data-bs-toggle="dropdown"style={{textDecoration:"none"}}><span className='responive_home_icon'><PrecisionManufacturingOutlinedIcon /></span>
        <span class="dropdown-toggle" >Products</span>
        </Link>
       
        <div class="dropdown-menu">
                                            <Link className="dropdown-item" to="/wikus" style={{textDecoration:"none"}} onClick={closeMenu}>Wikus - Germany Bimetal Band Saw Blades</Link>
                                            <Link  className="dropdown-item" to="/tenryu" style={{textDecoration:"none"}} onClick={closeMenu}>Tenryu - Japan TCT & HSS Circular Cutters</Link>
                                            <Link className="dropdown-item" to="/solar" style={{textDecoration:"none"}} onClick={closeMenu}>Solar Diamonds Tools</Link>
                                            <Link class="dropdown-item" to="/singular" style={{textDecoration:"none"}} onClick={closeMenu}>Singular - Taiwan Band Saw & Circular Saw Machine</Link>

{/*                                             
                                            <Link className="dropdown-item" to="/esconfiles">Escon Files & Tools
                                            Classic Industrial V-Belts</Link>
                                          
                                            <Link className="dropdown-item" to="/magicut">Magicut Hole Saws</Link>
                                            <Link className="dropdown-item" to="/hardcastel">Hardcastle Lubricants & Coolants</Link> */}
                                            <Link className="dropdown-item" to="/hardcastel" style={{textDecoration:"none"}} onClick={closeMenu}>Hardcastle Lubricants & Coolants</Link>
                                            <Link className="dropdown-item" to="/othercut" style={{textDecoration:"none"}} onClick={closeMenu} >Other Products</Link>

                                        </div>
        </div>

        <div class="nav-item dropdown">
        <Link to="" class="nav-link" data-bs-toggle="dropdown"style={{textDecoration:"none"}}><span className='responive_home_icon'><ManageAccountsOutlinedIcon /></span>
        <span class="dropdown-toggle" >Services</span>
        </Link>
       
        <div class="dropdown-menu">
                                            <Link className="dropdown-item" to="/UmangMarktingService" style={{textDecoration:"none"}} onClick={closeMenu}>Umang Markting Service</Link>
                                            <Link  className="dropdown-item" to="/UmangEngineeringServices" style={{textDecoration:"none"}} onClick={closeMenu}>Umang EngineeringServices</Link>

                                        </div>
        </div>
      
        {/* <Link  class="nav-item nav-link" style={{textDecoration:"none"}}><span className='responive_home_icon'><ManageAccountsOutlinedIcon /></span>Service
        
        <div class="dropdown-menu">
                                           
                                            <Link  className="dropdown-item" to="/UmangMarktingService" style={{textDecoration:"none"}}>Umang Marketing Services</Link>
                                            <Link className="dropdown-item" to="/UmangEngineeringServices" style={{textDecoration:"none"}}>Umang Engineering Services</Link>

                                        </div>

        </Link> */}
                                    <Link to="/blog" class="nav-item nav-link" onClick={closeMenu}><span className='responive_home_icon' ><ViewQuiltOutlinedIcon /></span>Blog</Link>
                                    
                                    <Link to="/contact" class="nav-item nav-link" onClick={closeMenu}><span className='responive_home_icon' ><PermContactCalendarOutlinedIcon /></span>Contact</Link>
                                    
                                 
        <Link to='/' class="btn btn-outline-primary responive_contact_button" style={{textDecoration:"none"}}><MdOutlinePhoneInTalk className='phone-body' style={{fontSize:"20px",textDecoration:"none"}}/>+91 8041145681 / 9606645009</Link>    
       
           <Link to="/" class="nav-item nav-link">
                       <div style={{display:"flex"}} className='resposnive_certified'>
           <img src='./img/iso.png' style={{fontSize:"40px",color:"orange",marginLeft:"10px",width:"30px",height:"30px"}}/>
            <div style={{display:"flex",flexDirection:"column",marginLeft:"10px",fontWeight:"bold",textAlign:"justify",color:"black",fontSize:"13px"}}>
              Certified Company<br/>ISO 9001:2015
              
            </div>
          </div>                    
           </Link>
        <div class="nav-btn ps-3">
          
        </div>
        </div>
        </div>
        </nav>
        </div>
        </div>
        </div>
        </div>

    </div>
  )
}

export default Navbar








// import React, { useState, useEffect } from 'react';
// import { Link } from 'react-router-dom';
// import { MdOutlinePhoneInTalk } from 'react-icons/md';
// import { FaBars, FaTimes } from 'react-icons/fa'; // Import FontAwesome icons for bars and close
// import './Navbar.css';

// const Navbar = () => {
//   const [isSticky, setIsSticky] = useState(false);
//   const [isMenuOpen, setIsMenuOpen] = useState(false); // State for menu open/close

//   useEffect(() => {
//     const handleScroll = () => {
//       if (window.scrollY > 100) {
//         setIsSticky(true);
//       } else {
//         setIsSticky(false);
//       }
//     };

//     window.addEventListener('scroll', handleScroll);

//     return () => {
//       window.removeEventListener('scroll', handleScroll);
//     };
//   }, []);

//   // Function to toggle the menu state
//   const toggleMenu = () => {
//     setIsMenuOpen(!isMenuOpen);
//   };

//   return (
//     <div>
//       <div className="container-fluid header-top">
//         <div className="container d-flex align-items-center">
//           <div className="d-flex align-items-center h-100">
//             <a href="#" className="navbar-brand" style={{ height: '125px' }}>
//               <h1 className="text-primary mb-0">
//                 <img src="img/logoo.png" alt="" className="zoom-image_logo" />
//               </h1>
//             </a>
//           </div>
//           <div className="w-100 h-100">
//             <div className="topbar px-0 py-2 d-none d-lg-block" style={{ height: '45px' }}>
//               <div className="row gx-0 align-items-center">
//                 <div className="col-lg-8 text-center text-lg-center mb-lg-0">
//                   <div className="d-flex flex-wrap">
//                     <div className="border-end border-primary pe-3" style={{ marginLeft: '-30px' }}>
//                       <a href="#" className="text-muted small">
//                         <i className="fas fa-map-marker-alt text-primary me-2"></i>
//                         <span style={{ fontSize: '12px' }}>
//                           SM-118 ITI Industrial Estate, Whitefield Main Rd, Devasandra Industrial Estate, Mahadevapura, Bengaluru, Karnataka-560048
//                         </span>
//                       </a>
//                     </div>
//                   </div>
//                 </div>
//                 <div className="col-lg-4 text-center text-lg-end">
//                   <div className="d-flex justify-content-end">
//                     <div className="d-flex border-end border-primary pe-3">
//                       <a className="btn p-0 text-primary me-3" href="sales@umangmarketingblr.com">
//                         <i className="fas fa-envelope text-primary me-2"></i>
//                         <span style={{ color: 'white', marginRight: '9px', fontSize: '12px' }}>
//                           sales@umangmarketingblr.com
//                         </span>
//                       </a>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//             <div className={`sticky-wrapper nav-bar px-0 py-lg-0 responsive_sticky ${isSticky ? ' sticky' : ''}`} style={{ height: '80px' }}>
//               <nav className="navbar navbar-expand-lg navbar-light d-flex justify-content-lg-end">
//                 <a href="#" className="navbar-brand-2">
//                   <img src="img/logoo.png" alt="" style={{ height: '60px' }} />
//                 </a>
//                 <button className="navbar-toggler" type="button" onClick={toggleMenu} aria-expanded={isMenuOpen}>
//                   {/* Toggle between hamburger and close icon */}
//                   <span>{isMenuOpen ? <FaTimes /> : <FaBars />}</span>
//                 </button>
//                 <div className={`collapse navbar-collapse ${isMenuOpen ? 'show' : ''}`} id="navbarCollapse">
//                   <div className="navbar-nav mx-0 mx-lg-auto">
//                     <a href="/" className="nav-item nav-link active">
//                       Home
//                     </a>
//                     <a href="/about" className="nav-item nav-link">
//                       About
//                     </a>
//                     <div className="nav-item dropdown">
//                       <a href="/products" className="nav-link" data-bs-toggle="dropdown">
//                         <span className="dropdown-toggle">Products</span>
//                       </a>
//                       <div className="dropdown-menu">
//                         <Link className="dropdown-item" to="/wikus" style={{ textDecoration: 'none' }}>
//                           Wikus - Germany Bimetal Band Saw Blades
//                         </Link>
//                         <Link className="dropdown-item" to="/tenryu" style={{ textDecoration: 'none' }}>
//                           Tenryu - Japan TCT & HSS Circular Cutters
//                         </Link>
//                         <Link className="dropdown-item" to="/solar" style={{ textDecoration: 'none' }}>
//                           Solar Diamonds Tools
//                         </Link>
//                         <Link className="dropdown-item" to="/singular" style={{ textDecoration: 'none' }}>
//                           Singular
//                         </Link>
//                         <Link className="dropdown-item" to="/othercut" style={{ textDecoration: 'none' }}>
//                           Other Cutting Tools
//                         </Link>
//                       </div>
//                     </div>
//                     <a href="/service" className="nav-item nav-link">
//                       Service
//                     </a>
//                     <a href="/blog" className="nav-item nav-link">
//                       Blog
//                     </a>
//                     <a href="/contact" className="nav-item nav-link">
//                       Contact
//                     </a>
//                     <a href="" className="btn btn-outline-primary responive_contact_button" style={{ width: '380px' }}>
//                       <MdOutlinePhoneInTalk className="phone-body" style={{ fontSize: '20px' }} />
//                       +91 8041145681
//                     </a>
//                   </div>
//                 </div>
//               </nav>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Navbar;
