// import React from 'react';
// import 'slick-carousel/slick/slick.css'; 
// import 'slick-carousel/slick/slick-theme.css';
// import Slider from "react-slick";
// import './Testinomial.css';

// const testimonials = [
//     {
//         name: "Person Name 1",
//         profession: "Profession 1",
//         image: "img/testimonial-1.jpg",
//         rating: 4,
//         text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quam soluta neque ab repudiandae reprehenderit ipsum eos cumque esse repellendus impedit."
//     },
//     {
//         name: "Person Name 2",
//         profession: "Profession 2",
//         image: "img/testimonial-2.jpg",
//         rating: 5,
//         text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quam soluta neque ab repudiandae reprehenderit ipsum eos cumque esse repellendus impedit."
//     },
//     {
//         name: "Person Name 3",
//         profession: "Profession 3",
//         image: "img/testimonial-3.jpg",
//         rating: 5,
//         text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quam soluta neque ab repudiandae reprehenderit ipsum eos cumque esse repellendus impedit."
//     }
// ];

// const Testinomial = () => {
//     const settings = {
//         dots: true,
//         infinite: true,
//         speed: 500,
//         slidesToShow: 1,
//         slidesToScroll: 1,
//         autoplay: true,
//         autoplaySpeed: 3000
//     };
//   return (
//     <div className="testimonial-container bg-dark py-5">
//     <div className="container py-5">
//         <div className="row g-5">
//             <div className="col-lg-6 col-xl-5 wow fadeInUp">
//                 <h4 className="text-primary">Testimonial</h4>
//                 <h1 className="display-4 text-white mb-4">Powerfull Praise Hear From Our Customers</h1>
//                 <p className="mb-4" style={{color:"white"}}>Lorem ipsum dolor sit amet consectetur adipisicing elit. Mollitia hic aspernatur unde magnam necessitatibus provident iusto maxime nobis esse eligendi.</p>
//                 <a className="btn btn-light py-3 px-5" href="#">View All Reviews</a>
//             </div>
//             <div className="col-lg-6 col-xl-7 wow fadeInUp">
//                 <Slider {...settings} className="testimonial-carousel">
//                     {testimonials.map((testimonial, index) => (
//                         <div key={index} className="testimonial-item">
//                             <div className="testimonial-quote"><i className="fa fa-quote-right fa-2x"></i></div>
//                             <div className="testimonial-inner p-4">
//                                 <img src={testimonial.image} className="img-fluid" alt={testimonial.name} />
//                                 <div className="ms-4">
//                                     <h4>{testimonial.name}</h4>
//                                     <p>{testimonial.profession}</p>
//                                     <div className="d-flex text-primary">
//                                         {[...Array(testimonial.rating)].map((star, i) => (
//                                             <i key={i} className="fas fa-star"></i>
//                                         ))}
//                                         {[...Array(5 - testimonial.rating)].map((star, i) => (
//                                             <i key={i} className="fas fa-star text-body"></i>
//                                         ))}
//                                     </div>
//                                 </div>
//                             </div>
//                             <div className="customer-text p-4">
//                                 <p className="mb-0">{testimonial.text}</p>
//                             </div>
//                         </div>
//                     ))}
//                 </Slider>
//             </div>
//         </div>
//     </div>
// </div>
//   )
// }

// export default Testinomial

//correct 


// import React from 'react';
// import 'slick-carousel/slick/slick.css'; 
// import 'slick-carousel/slick/slick-theme.css';
// import Slider from "react-slick";
// import './Testinomial.css';

// const testimonials = [
//     {
//         name: "Person Name 1",
//         profession: "Profession 1",
//         image: "img/testimonial-1.jpg",
//         rating: 4,
//         text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quam soluta neque ab repudiandae reprehenderit ipsum eos cumque esse repellendus impedit."
//     },
//     {
//         name: "Person Name 2",
//         profession: "Profession 2",
//         image: "img/testimonial-2.jpg",
//         rating: 5,
//         text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quam soluta neque ab repudiandae reprehenderit ipsum eos cumque esse repellendus impedit."
//     },
//     {
//         name: "Person Name 3",
//         profession: "Profession 3",
//         image: "img/testimonial-3.jpg",
//         rating: 5,
//         text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quam soluta neque ab repudiandae reprehenderit ipsum eos cumque esse repellendus impedit."
//     }
// ];

// const Testinomial = () => {
//     const settings = {
//         dots: true,
//         infinite: true,
//         speed: 500,
//         slidesToShow: 1,
//         slidesToScroll: 1,
//         autoplay: true,
//         autoplaySpeed: 3000
//     };
//   return (
//     <div className="testimonial-container py-5" style={{backgroundColor: '#fff1e0'}}>
//     <div className="container py-5">
//         <div className="row g-5">
//             <div className="col-lg-6 col-xl-5 wow fadeInUp">
//                 <h4 style={{color: 'rgb(16, 16, 16)'}}>Testimonial</h4>
//                 <h1 className="display-4 mb-4" style={{color: '#0F253B'}}>Powerfull Praise Hear From Our Customers</h1>
//                 <p className="mb-4" style={{color: '#0F253B'}}>Lorem ipsum dolor sit amet consectetur adipisicing elit. Mollitia hic aspernatur unde magnam necessitatibus provident iusto maxime nobis esse eligendi.</p>
//                 <a className="btn py-3 px-5" href="#" style={{backgroundColor: '#f68a0a', color: 'white'}}>View All Reviews</a>
//             </div>
//             <div className="col-lg-6 col-xl-7 wow fadeInUp">
//                 <Slider {...settings} className="testimonial-carousel">
//                     {testimonials.map((testimonial, index) => (
//                         <div key={index} className="testimonial-item" style={{backgroundColor: 'white'}}>
//                             <div className="testimonial-quote"><i className="fa fa-quote-right fa-2x" style={{color: '#f68a0a'}}></i></div>
//                             <div className="testimonial-inner p-4">
//                                 <img src={testimonial.image} className="img-fluid" alt={testimonial.name} />
//                                 <div className="ms-4">
//                                     <h4 style={{color: '#0F253B'}}>{testimonial.name}</h4>
//                                     <p style={{color: '#0F253B'}}>{testimonial.profession}</p>
//                                     <div className="d-flex">
//                                         {[...Array(testimonial.rating)].map((star, i) => (
//                                             <i key={i} className="fas fa-star" style={{color: '#f68a0a'}}></i>
//                                         ))}
//                                         {[...Array(5 - testimonial.rating)].map((star, i) => (
//                                             <i key={i} className="fas fa-star" style={{color: '#0F253B'}}></i>
//                                         ))}
//                                     </div>
//                                 </div>
//                             </div>
//                             <div className="customer-text p-4" style={{backgroundColor: 'rgb(239 143 27 / 37%)'}}>
//                                 <p className="mb-0" style={{color: '#0F253B'}}>{testimonial.text}</p>
//                             </div>
//                         </div>
//                     ))}
//                 </Slider>
//             </div>
//         </div>
//     </div>
// </div>
//   )
// }

// export default Testinomial
import React, { useState, useEffect } from 'react';
import './Testinomial.css';

const testimonials = [
  {
    id: 1,
    name: 'Michael Davis CEO',
    text: '"The level of expertise and service provided by Umang Marketing is exceptional. Their Bimetal Band Saw Blades and TCT Cutters are of the highest quality, and the team goes above and beyond to ensure customer satisfaction.", Precision Tools Ltd.',
    image: 'img/testimonial-1.jpg'
  },
  {
    id: 2,
    name: 'Michale William',
    text: 'We’ve been using Umang Marketing’s products for years, and they consistently deliver outstanding performance. Their dedication to quality and prompt service sets them apart from the competition. We highly recommend them.',
    image: 'img/testimonial-1.jpg'
  },
  {
    id: 3,
    name: 'Dylan Adams',
    text: 'Umang Marketing’s tools have significantly improved our production capabilities. Their team is knowledgeable, responsive, and always available to provide support when needed. We couldn’t ask for a better partner in the industry.',
    image: 'img/testimonial-1.jpg'
  },
  {
    id: 4,
    name: 'Michale William',
    text: 'We’ve been using Umang Marketing’s products for years, and they consistently deliver outstanding performance. Their dedication to quality and prompt service sets them apart from the competition. We highly recommend them.',
    image: 'img/testimonial-1.jpg'
  },
  // Add more testimonials as needed
];

const limitedStyle = {
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: 2, // Limit to 2 lines
  overflow: 'hidden',  // Hide overflow text
  textOverflow: 'ellipsis', // Add ellipsis (...) at the end
  lineHeight: '1.5',  // Adjust line height as needed
  maxHeight: '3em',    // Adjust based on line-height (2 lines)
  color: '#0F253B',    // Set text color directly here
};

const TestimonialCard = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const nextTestimonial = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
  };

  const prevTestimonial = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + testimonials.length) % testimonials.length);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      nextTestimonial();
    }, 5000); // Change slide every 5 seconds

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="testimonial-container ">
      <div className="container py-5">
            <div className="d-flex flex-column mx-auto text-center mb-5 wow fadeInUp" data-wow-delay="0.2s" style={{maxWidth: "800px"}}>
                <h4 className='text-primary'style={{textAlign:"left"}}>Our Testimonial</h4>
                <h1 className="display-4 mb-4" style={{color: '#0F253B',textAlign:"left"}}>Our Clients Says!</h1>
                <p className="mb-0" style={{color: '#0F253B'}}>At Umang Marketing, we pride ourselves on delivering cutting-edge industrial solutions and unmatched service. Our clients trust us to provide the tools and expertise that drive their success. Here’s what they have to say about working with us.</p>
            </div>

      <div className="testimonial-slider" style={{ transform: `translateX(calc(-${currentIndex * 100}% - ${currentIndex * 3}rem))` }}>
        {testimonials.map((testimonial) => (
          <div key={testimonial.id} className="testimonial-card">
            <img
              src={testimonial.image}
              alt={`Profile of ${testimonial.name}`}
              className="testimonial-image"
            />
            <p className="testimonial-text">{testimonial.text}</p>
            <h3 className="testimonial-name">{testimonial.name}</h3>
          </div>
        ))}
      </div>
      <div className="testimonial-background"></div>
      <div className="slider-controls">
        <button onClick={prevTestimonial}>&lt;</button>
        <button onClick={nextTestimonial}>&gt;</button>
      </div>
    </div>
    </div>
  );
};

export default TestimonialCard;