// import React from 'react'
// import './Escon.css'

// const Esconfiles = () => {
//   return (
//     <div className='my_chart' style={{width:"900px"}}>
// 		<h3>Escon Files and Tools</h3>
// 		<div className='responive_escon'>
//       <div>
// 		 <img src="./img/escon.png" />
// 	  </div>
// 	  <div>
// 		 <p>Umang Marketing is the authorized distributors for world class Escon Steel Rasp Files, Round Bastard Files, Flat Bastard, Round Files, Round Smooth Files, Steel Machinists Files, Half Round Bastard Steel Files, Cut Slim Taper Saw Files etc.

// We also supply Mist Coolants for TCT Circular Saw cutter applications which helps to enhance the cutter life.</p>
// 	  </div>
// 	  </div>

// 	  <div style={{overflowY:"scroll",height:"1300px",width:"1140px"}}>
// 		 <h3>Machinist Files</h3>
// 		 <div >
// 		    <img src="./img/m1.png" style={{width:"470px"}}/>
// 			<img src="./img/m2.png" style={{width:"470px"}}/>
// 			<img src="./img/m3.png" style={{width:"470px"}}/>
// 			<img src="./img/m4.png" style={{width:"470px"}}/>
// 			<img src="./img/m5.png" style={{width:"470px"}}/>
// 			<img src="./img/m6.png" style={{width:"470px"}}/>
// 			<img src="./img/m7.png" style={{width:"470px"}}/>
// 			<img src="./img/m8.png" style={{width:"470px"}}/>
// 			<img src="./img/m9.png" style={{width:"470px"}}/>
// 			<img src="./img/m10.png" style={{width:"470px"}}/>
// 			<img src="./img/m11.png" style={{width:"470px"}}/>
// 			<img src="./img/m12.png" style={{width:"470px"}}/>
// 			<img src="./img/m13.png" style={{width:"470px"}}/>
// 			<img src="./img/m14.png" style={{width:"470px"}}/>
// 			<img src="./img/m15.png" style={{width:"470px"}}/>
// 		 </div>
		
// 	  </div>
//     </div>
//   )
// }

// export default Esconfiles




import React from 'react';
import './Escon.css';
import { FaArrowCircleRight } from "react-icons/fa";

const Esconfiles = () => {
  return (
    <div className='my_chart'>
      <h1>Escon Files and Tools</h1>
      <div className='responsive_escon'>
        <div>
          <img src='./img/escon.png' alt='Escon'/>
        </div>
        <div>
          <p>
            Umang Marketing is the authorized distributor for world-class Escon Steel Rasp Files, Round Bastard Files, Flat Bastard, Round Files, Round Smooth Files, Steel Machinists Files, Half Round Bastard Steel Files, Cut Slim Taper Saw Files, etc.
            We also supply Mist Coolants for TCT Circular Saw cutter applications which helps to enhance the cutter life.
          </p>
        </div>
      </div>

      <div className='machinist_files_container'>
        <h3>Machinist Files</h3>
        <div className='machinist_files_images'>
          {Array.from({ length: 14 }, (_, index) => (
            <img key={index} src={`./img/m${index + 1}.png`} alt={`Machinist File ${index + 1}`} />
          ))}
          {/* <img src='./img/m15.png'/> */}
        </div>
        <FaArrowCircleRight style={{color:"#0f253b",marginRight:"7px"}}/> <h6>Scroll Horizontally</h6>
        <h2 style={{textAlign:"left"}}>File Selection</h2>
        <img src='./img/m15.png'/>
      </div>
    </div>
  );
};

export default Esconfiles;
